import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';
import { routes as appRoutes } from './app/routes';
import MobileRoute from './mobile-route/page';

const AppLayout = lazyWithRetry(() => import('./app/layout'));
const FastLogin = lazyWithRetry(() => import('./fast_login/page'));
const Layout = lazyWithRetry(() => import('./layout'));
const LiteOnboarding = lazyWithRetry(() => import('./lite_onboarding/page'));
const Login = lazyWithRetry(() => import('./page'));
const Logout = lazyWithRetry(() => import('./logout/page'));
const PlusOnboarding = lazyWithRetry(() => import('./plus_onboarding/page'));

export const routes: RouteObject[] = [
  {
    path: '/mobile-route/*',
    element: <MobileRoute />,
  },
  {
    path: '/',
    element: (
      <Suspense>
        <Layout />
      </Suspense>
    ),
    children: [
      {
        element: (
          <Suspense>
            <Login />
          </Suspense>
        ),
        index: true,
      },
      {
        path: 'logout',
        element: (
          <Suspense>
            <Logout />
          </Suspense>
        ),
      },
      {
        path: 'lite-onboarding',
        element: (
          <Suspense>
            <LiteOnboarding />
          </Suspense>
        ),
      },
      {
        path: 'plus-onboarding',
        element: (
          <Suspense>
            <PlusOnboarding />
          </Suspense>
        ),
      },
      {
        path: 'fast-login',
        element: (
          <Suspense>
            <FastLogin />
          </Suspense>
        ),
      },
      {
        path: 'app',
        element: (
          <Suspense>
            <AppLayout />
          </Suspense>
        ),
        children: appRoutes,
      },
    ],
  },
];
