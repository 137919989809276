import {
  FieldPolicy,
  FieldReadFunction,
  KeyArgsFunction,
  TypePolicy,
} from '@apollo/client/cache/inmemory/policies';
import { relayStylePagination } from '@apollo/client/utilities';

export const RelayKeyArgsFunction: KeyArgsFunction = (args) => {
  if (!args) return [];
  const keyArgs = Object.keys(args).filter(
    (arg) => !['before', 'after'].includes(arg),
  );
  return keyArgs;
};

export const mergeRootQuery: TypePolicy['merge'] = (existing, incoming) => {
  if (!existing) return incoming;
  return {
    ...existing,
    ...incoming,
  };
};

export const rootQueryTypePolicy: (
  c: string[],
  fields?: { [fieldName: string]: FieldPolicy<any> | FieldReadFunction<any> },
) => TypePolicy = (connectionFields, fields = undefined) => {
  const calculatedFields = connectionFields.reduce(
    (prev, field) => ({
      ...prev,
      [field]: relayStylePagination(RelayKeyArgsFunction),
    }),
    {},
  );
  return {
    keyFields: false,
    merge: mergeRootQuery,
    fields: {
      ...calculatedFields,
      ...fields,
    },
  };
};
