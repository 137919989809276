import { useEffect } from 'react';

export default function MobileRoute() {
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      // Es un dispositivo iOS
      window.location.href = 'https://apps.apple.com/app/id6469697175';
    } else if (/android/i.test(userAgent)) {
      // Es un dispositivo Android
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.anonymous.montufrontend';
    }
  }, []);
  return <></>;
}
